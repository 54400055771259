import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton, styled, Theme } from "@mui/material";

const IconButtonStyled = styled(IconButton)(({ theme }) => ({
  color: "white",
  position: "absolute",
  backgroundColor: "#0006",
  padding: 0,
  minWidth: 0,
  minHeight: 0,
  width: theme.spacing(4),
  height: theme.spacing(4),
  "&:hover": {
    backgroundColor: "#000A",
  }
}));

export type IconButtonProps = {
  top?: string | number;
  right?: string | number;
  left?: string | number;
  bottom?: string | number;
  icon: IconProp;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

const calcSize = (theme:Theme, value?:string | number):(string | number | undefined) => {
  if(typeof value === "string"){
    return value
  } else if(typeof value === "number") {
    return theme.spacing(value)
  }
  return undefined
}

export const IconButtonAbsolute = (props: IconButtonProps) => {
  return (
    <IconButtonStyled
      sx={theme => ({
        top: calcSize(theme,props.top),
        right: calcSize(theme,props.right),
        left: calcSize(theme,props.left),
        bottom: calcSize(theme,props.bottom),
      })}
      onClick={props.onClick}
      {...props}
    >
      <FontAwesomeIcon icon={props.icon} size="xs" />
    </IconButtonStyled>
  );
};
