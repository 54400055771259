import { arrayMoveImmutable } from "array-move";
import { genKey } from "draft-js";
import { User } from "firebase/auth";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  FieldValue,
  getDoc,
  onSnapshot,
  query,
  Unsubscribe,
  updateDoc,
  where,
} from "firebase/firestore";
import { SwiperProps } from "swiper/react";
import { DateCtl, MainStatic } from "../components/core-sub/Controller";
import { db, dbTimestamp } from "../components/core-sub/Controller/firebase";
import { StockDisplayProps } from "../components/core-sub/StockDisplay";
import { VisibilityTabsValue } from "../components/core-sub/VisibilityTabs";

export type SlideshowSetData = Omit<
  Slideshow,
  | "id"
  | "update"
  | "set"
  | "save"
  | "data"
  | "remove"
  | "datecreate"
  | "datemodified"
  | "sorting"
  | "contenting"
  | "toJSON"
  | "toSlides"
>;
export type SlideShowData = Omit<SlideshowSetData, "contents"> & {
  contents: SlideshowContentData[];
};

export class Slideshow {
  id: string;
  title: string;
  feature: StockDisplayProps | null;
  contents: SlideshowContent[];
  visibility: VisibilityTabsValue;
  user: string;
  prefix: string;
  datecreate: number;
  datemodified: number;

  constructor(data?: Partial<Slideshow>) {
    this.id = data?.id ?? "";
    this.title = data?.title ?? "";
    this.feature = data?.feature ?? null;
    this.contents = (data?.contents ?? []).map(
      (content) => new SlideshowContent(content)
    );
    this.visibility = data?.visibility ?? "private";
    this.user = data?.user ?? "";
    this.prefix = data?.prefix ?? MainStatic.prefix ?? "";
    this.datecreate = DateCtl.toNumber(data?.datecreate);
    this.datemodified = DateCtl.toNumber(data?.datemodified);
  }

  data(): SlideShowData {
    const {
      id,
      update,
      data,
      contents,
      datecreate,
      datemodified,
      save,
      remove,
      sorting,
      contenting,
      toJSON,
      ...newData
    } = this;
    return { ...newData, contents: contents.map((c) => c.data()) };
  }

  toJSON(): Pick<
    Slideshow,
    | "id"
    | "title"
    | "feature"
    | "contents"
    | "visibility"
    | "user"
    | "prefix"
    | "datecreate"
    | "datemodified"
  > {
    const {
      id,
      title,
      feature,
      contents,
      visibility,
      user,
      prefix,
      datecreate,
      datemodified,
    } = this;
    return {
      id,
      title,
      feature,
      contents,
      visibility,
      user,
      prefix,
      datecreate,
      datemodified,
    };
  }

  sorting(oldIndex: number, newIndex: number): Slideshow {
    this.contents = arrayMoveImmutable(this.contents, oldIndex, newIndex);
    return new Slideshow(this);
  }

  contenting = {
    add: (content: SlideshowContent): Slideshow => {
      const index = this.contents.findIndex((c) => c.key === content.key);
      if (index > -1) {
        this.contents[index] = content;
      } else {
        this.contents = this.contents.concat(content);
      }
      return new Slideshow(this);
    },
    update: (content: SlideshowContent): Slideshow => {
      const index = this.contents.findIndex((c) => c.key === content.key);
      if (index > -1) {
        this.contents[index] = content;
      }
      return new Slideshow(this);
    },
    delete: (content: SlideshowContent): Slideshow => {
      this.contents = this.contents.filter((c) => c.key !== content.key);
      return new Slideshow(this);
    },
  };

  set<T extends keyof SlideshowSetData>(
    field: T,
    value: this[T] | ((data: this[T]) => this[T])
  ): Slideshow {
    this[field] = value instanceof Function ? value(this[field]) : value;
    return new Slideshow(this);
  }

  async update<T extends keyof SlideShowData>(
    field: T,
    value: this[T] | FieldValue | ((data: this[T]) => this[T])
  ): Promise<void> {
    if (this.id) {
      await updateDoc(Slideshow.doc(this.id), {
        [field]: value instanceof Function ? value(this[field]) : value,
        datemodified: dbTimestamp(),
      });
    }
  }

  async remove() {
    if (this.id) {
      await deleteDoc(Slideshow.doc(this.id));
    }
  }

  async save() {
    const data = this.data();
    if (this.id) {
      await updateDoc(Slideshow.doc(this.id), {
        ...data,
        datemodified: dbTimestamp(),
      });
    } else {
      await addDoc(Slideshow.collection(), {
        ...data,
        datecreate: dbTimestamp(),
        datemodified: dbTimestamp(),
      });
    }
  }

  toSlides(): {
    key: string;
    title?: string;
    image?: StockDisplayProps | null;
    secondary?: string;
  }[] {
    return this.contents.map((content) => ({
      key: content.key,
      title: content.header,
      secondary: content.body,
      image: content.image,
    }));
  }

  protected static collection() {
    return collection(db, "clients", `${MainStatic.prefix}`, "slides");
  }
  protected static doc(id: string) {
    return doc(db, "clients", `${MainStatic.prefix}`, "slides", id);
  }

  static watchMany(
    user: User,
    callback: (docs: Slideshow[]) => void
  ): Unsubscribe {
    return onSnapshot(
      query(this.collection(), where("user", "==", user.uid)),
      (snapshot) => {
        const docs = snapshot.docs.map(
          (doc) => new Slideshow({ ...doc.data(), id: doc.id })
        );
        callback(docs);
      }
    );
  }

  static async getOne(id: string): Promise<Slideshow> {
    const snapshot = await getDoc(Slideshow.doc(id));
    if (snapshot.exists()) {
      return new Slideshow({ ...snapshot.data(), id });
    } else {
      throw new Error("Slideshow not found");
    }
  }

  static getVisibility(
    docs: Slideshow[],
    visibility: Slideshow["visibility"]
  ): Slideshow[] {
    return docs.filter((doc) => doc.visibility === visibility);
  }

  static async add(user: User, title: string) {
    if (user && title) {
      const slide = new Slideshow({ title, user: user.uid });
      await addDoc(this.collection(), {
        ...slide.data(),
        datecreate: dbTimestamp(),
        datemodified: dbTimestamp(),
      });
    }
  }

  static options: SwiperProps = {
    spaceBetween: 50,
    slidesPerView: 1,
    pagination: {
      el: ".swiper-pagination",
      type: "bullets",
      clickable: true,
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    observer: true,
    autoplay: { delay: 3000 },
  };
}

export type SlideshowContentData = Omit<
  SlideshowContent,
  "data" | "isComplete" | "set"
>;
export class SlideshowContent {
  key: string;
  header: string;
  body: string;
  image: StockDisplayProps | null;

  constructor(data?: Partial<SlideshowContent>) {
    this.key = data?.key ?? genKey();
    this.header = data?.header ?? "";
    this.body = data?.body ?? "";
    this.image = data?.image ?? null;
  }

  data(): SlideshowContentData {
    const { data, ...newData } = this;
    return newData;
  }

  isComplete(): boolean {
    return (
      ["body", "header", "image", "key"] as (keyof SlideshowContentData)[]
    ).every((key) => Boolean(this[key]));
  }

  set<T extends keyof SlideshowContentData>(
    field: T,
    value: this[T] | ((data: this[T]) => this[T])
  ): SlideshowContent {
    this[field] = value instanceof Function ? value(this[field]) : value;
    return new SlideshowContent(this);
  }
}
