import {
  Card as MuiCard,
  CardContent as MuiCardContent,
  Typography,
  Grid,
  styled,
  Box,
} from "@mui/material";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import React from "react";
import { IconButtonAbsolute } from "./icon.button.absolute";
import { faGripLines } from "@fortawesome/pro-regular-svg-icons";
import { StockDisplayProps, StockDisplay } from "../../components/core-sub/StockDisplay";
import { SlideshowContent } from "../../controllers/slide";

const CardRoot = styled(Box)(({ theme }) => ({
  position: "relative",
  borderRadius: theme.spacing(2),
  overflow: "hidden",
  "&:before": { content: "''", display: "block", paddingTop: "100%" },
  "& .stock-display-credit": {
    padding: theme.spacing(0, 0.5, 0, 1)
  }
}));

const Card = styled(MuiCard)({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
});

const CardContent = styled(MuiCardContent)(({ theme }) => ({
  padding: theme.spacing(0.5, 2),
}));

const SortHandler = SortableHandle(() => (
  <IconButtonAbsolute icon={faGripLines} top={3} left={2} />
));

interface SortableElementProps {
  index: number;
  length?: number;
  itemId?: string;
  label?: string;
  desc?: string;
  image?: StockDisplayProps | null;
  itemIndex: number;
  children?: React.ReactNode;
}

export const SortCardItem = SortableElement<SortableElementProps>(
  ({
    index,
    length,
    itemId,
    label,
    desc,
    image,
    itemIndex,
    ...props
  }: SortableElementProps) => {
    return (
      <Grid item xs={6} sm={4}>
        <CardRoot>
          <Card key={itemId} {...props}>
            <StockDisplay
              ratio={1}
              {...image}
              rootProps={{ sx: { height: "calc(100% * 2 / 3)" } }}
            />
            <CardContent>
              <Typography variant="caption">{`[${
                itemIndex + 1
              }/${length}]`}</Typography>
              <Typography gutterBottom variant="h6" component="div">
                {label}
              </Typography>
            </CardContent>
            <SortHandler />
            {props.children}
          </Card>
        </CardRoot>
      </Grid>
    );
  }
);

interface SortContainerProps {
  items: SlideshowContent[];
  component: (
    doc: SlideshowContent,
    index: number,
    docs: SlideshowContent[]
  ) => React.ReactNode;
}

export const SortContainerCard = SortableContainer<SortContainerProps>(
  ({ items, component }: SortContainerProps) => (
    <Grid container spacing={2}>
      {items.map((item, index) => component(item, index, items))}
    </Grid>
  )
);
