import { BrowserRouter, Routes, Route } from "react-router-dom";

import { PageNotFound } from "./pages/not.found";
import { PageSlideManager } from "./pages/PageSlideManager";
import { PageSlideEditor } from "./pages/PageSlideEdit";
import { PageSlidePreview } from "./pages/PageSlidePreview";
import CoreProvider from "./components/core-sub/context";
import { app } from "./components/core-sub/Controller/firebase";
import { useEffect, useState } from "react";
import { AppMenu, getAppMenu } from "./components/core-sub/app.menu";

const Routing = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/v/preview/:slideId" element={<PageSlidePreview />} />
        <Route path="/v/preview" element={<PageSlidePreview />} />
        <Route path="/edit/:slideId" element={<PageSlideEditor />} />
        <Route path="/*" element={<PageNotFound />} />
        <Route path="/" element={<PageSlideManager />} />
      </Routes>
    </BrowserRouter>
  );
};

function App() {
  const [appMenu, setAppMenu] = useState<AppMenu[]>([]);

  useEffect(() => {
    getAppMenu(app).then(setAppMenu);
  }, []);

  return (
    <CoreProvider
      firebaseApp={app}
      appMenu={appMenu}
      logo={process.env.REACT_APP_LOGO}
      sitename="SLIDESHOW"
    >
      <Routing />
    </CoreProvider>
  );
}

export default App;
