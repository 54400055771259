import { Avatar, Container, Link as MLink, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAlerts } from "../../components/core-sub/Alerts";
import { ContentHeader } from "../../components/core-sub/ContentHeader";
import { useCore } from "../../components/core-sub/context";
import { MainStatic } from "../../components/core-sub/Controller";
import { Time } from "../../components/core-sub/Controller/time";
import { DataGrid } from "../../components/core-sub/DataGrid";
import { KuiActionIcon } from "../../components/core-sub/KuiActionIcon";
import { KuiButton } from "../../components/core-sub/KuiButton";
import MainContainer from "../../components/core-sub/MainContainer";
import { usePopup } from "../../components/core-sub/Popup";
import { VisibilityValue } from "../../components/core-sub/Visibility";
import { VisibilityTabs } from "../../components/core-sub/VisibilityTabs";
import { Slideshow } from "../../controllers/slide";

export const PageSlideManager = () => {
  const { user, t } = useCore();
  const [slide, setSlide] = useState<{
    loading: boolean;
    docs: Slideshow[];
    tab: VisibilityValue;
  }>({
    loading: true,
    docs: [],
    tab: "public",
  });
  const { Popup } = usePopup();
  const nav = useNavigate();
  const { addAlert } = useAlerts();

  useEffect(() => {
    if (user.loading === false) {
      if (user.data) {
        return Slideshow.watchMany(user.data, (docs) => {
          setSlide((s) => ({ ...s, docs, loading: false }));
        });
      } else {
        setSlide((s) => ({ ...s, loading: false, docs: [] }));
      }
    } else {
      setSlide((s) => ({ ...s, loading: true, docs: [] }));
    }
    return () => {};
  }, [user]);

  const handleAddSlide = () => {
    Popup.prompt({
      title: t("Add $Name", { name: t("SLIDESHOW") }),
      text: t("Title"),
      icon: "plus-circle",
      onConfirm: async (title) => {
        if (title && user.data) {
          await Slideshow.add(user.data, title);
          addAlert({ label: t("$Name Added", { name: title }) });
          localStorage.setItem("viaibilityTabLastValue", "private");
          setSlide((s) => ({ ...s, tab: "private" }));
        }
      },
    });
  };
  const handleRemoveSlide = (slide: Slideshow) => () => {
    Popup.remove({
      title: t("Remove"),
      text: t("Do You Want To Remove $Name", { name: slide?.title ?? "" }),
      icon: "trash",
      onConfirm: async () => {
        if (user.data && slide.id) {
          await slide.update("visibility", "trash");
        }
      },
    });
  };
  const handleRestore = (slide: Slideshow) => async () =>
    await slide.update("visibility", "private");
  const handlePermanentRemove = (slide: Slideshow) => () => {
    Popup.remove({
      title: t("Remove Permanent"),
      text: t("Do You Want To Remove $Name Forever", { name: slide.title }),
      icon: "trash",
      onConfirm: async () => {
        await slide.remove();
        addAlert({ label: t("$Name Has Removed", { name: slide.title }) });
      },
    });
  };

  return (
    <MainContainer signInOnly>
      <Container maxWidth="md">
        <ContentHeader
          label={t("Manage $Name", { name: t("SLIDESHOW") })}
          breadcrumbs={[
            { label: t("Home") },
            { label: t("Manage $Name", { name: t("SLIDESHOW") }) },
          ]}
          actions={<KuiButton tx="add" onClick={handleAddSlide} />}
        />
        <VisibilityTabs
          value={slide.tab}
          onChange={(tab) =>
            tab !== slide.tab && setSlide((s) => ({ ...s, tab }))
          }
          items={slide.docs}
        />
        <DataGrid
          loading={slide.loading}
          rows={Slideshow.getVisibility(slide.docs, slide.tab)}
          columns={[
            {
              field: "action",
              headerName: " ",
              width: 64,
              renderCell: ({ row }) => {
                switch (slide.tab) {
                  case "public":
                  case "private":
                    return (
                      <>
                        <KuiActionIcon
                          tx="edit"
                          onClick={() => nav(`/edit/${row.id}`)}
                        />
                        <KuiActionIcon
                          tx="remove"
                          onClick={handleRemoveSlide(row)}
                        />
                      </>
                    );
                  case "trash":
                    return (
                      <>
                        <KuiActionIcon
                          tx="restore"
                          onClick={handleRestore(row)}
                        />
                        <KuiActionIcon
                          tx="remove"
                          onClick={handlePermanentRemove(row)}
                        />
                      </>
                    );
                  default:
                    return null;
                }
              },
              filterable: false,
              sortable: false,
              align: "center",
            },
            {
              field: "feature",
              headerName: " ",
              renderCell: ({ value }) => (
                <Avatar
                  variant="square"
                  src={MainStatic.imageSrcFromId(
                    value?.image?._id,
                    "thumbnail"
                  )}
                />
              ),
              width: 60,
              align: "center",
            },
            {
              field: "title",
              headerName: t("Title"),
              width: 360,
              renderCell: ({ row }) => (
                <Typography sx={{ color: "info.main" }} variant="inherit">
                  <MLink
                    component={Link}
                    to={`/v/preview/${row.id}`}
                    target="_blank"
                  >
                    {row.title}
                  </MLink>
                </Typography>
              ),
            },
            {
              field: "datemodified",
              headerName: t("Date"),
              renderCell: ({ value }) => new Time(value).toString(),
              width: 180,
            },
          ]}
        />
      </Container>
    </MainContainer>
  );
};
