import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Skeleton,
} from "@mui/material";
import React, { useEffect, useReducer } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { SortEnd } from "react-sortable-hoc";
import { useAlerts } from "../../components/core-sub/Alerts";
import { BackLink } from "../../components/core-sub/BackLink";
import { Container } from "../../components/core-sub/Container";
import { ContentHeader } from "../../components/core-sub/ContentHeader";
import { useCore } from "../../components/core-sub/context";
import { DialogImagePosition } from "../../components/core-sub/DialogImagePosition";
import { FabGroup, FabIcon } from "../../components/core-sub/FabGroup";
import { FeatureImageEdit } from "../../components/core-sub/FeatureImage";
import MainContainer from "../../components/core-sub/MainContainer";
import { StockDisplayProps } from "../../components/core-sub/StockDisplay";
import { TitleEdit } from "../../components/core-sub/TitleEdit";
import { VisibilitySelect } from "../../components/core-sub/Visibility";
import { Slideshow, SlideshowContent } from "../../controllers/slide";
import { IconButtonAbsolute } from "./icon.button.absolute";
import { PageSlideContent } from "./slide.content";
import { SortCardItem, SortContainerCard } from "./slide.content.card";
import { EditState } from "./state";

export const PageSlideEditor = () => {
  const { user, t } = useCore();
  const { slideId } = useParams();
  const nav = useNavigate();
  const [state, dispatch] = useReducer(EditState.reducer, new EditState());
  const { addAlert } = useAlerts();
  const location = useLocation();

  const handlePreview = () => {
    nav(`/v/preview`, { state: state.data.toJSON() });
  };

  const handlePushContent = (content: SlideshowContent) => {
    dispatch({ type: "dataset", value: (d) => d.contenting.add(content) });
    dispatch({ type: "set", data: { edit: null } });
  };

  const handleOnSortEnd = async ({ oldIndex, newIndex }: SortEnd) =>
    dispatch({ type: "dataset", value: (d) => d.sorting(oldIndex, newIndex) });

  const handleChangePos =
    (content: SlideshowContent) => (pos: StockDisplayProps["pos"]) => {
      dispatch({
        type: "dataset",
        value: (d) =>
          d.contenting.update(
            content.set("image", (im) =>
              im ? Object.assign(im, { pos }) : null
            )
          ),
      });
    };

  const handleSave = async () => {
    await state.data.save();
    addAlert({ label: t("Saved") });
  };

  useEffect(() => {
    if (user.loading === false && user.data && slideId) {
      if (location.state) {
        const { id, data } = location.state as any;
        dispatch({
          type: "set",
          data: {
            data: new Slideshow({ ...data, id }),
            loading: false,
            restrict: false,
          },
        });
      } else {
        Slideshow.getOne(slideId).then((data) => {
          dispatch({
            type: "set",
            data: {
              data,
              loading: false,
              restrict: !Boolean(data?.user === user.data?.uid),
            },
          });
        });
      }
    } else {
      dispatch({ type: "set", data: { loading: true } });
    }
  }, [user, slideId, location.state]);

  return (
    <>
      <MainContainer
        dense
        signInOnly
        loading={state.loading}
        restrict={state.restrict}
        sidebar={
          <React.Fragment>
            <BackLink to={"/"} divider />
            <ListItem divider>
              <Button
                fullWidth
                variant="outlined"
                size="large"
                startIcon={<FontAwesomeIcon icon={["far", "eye"]} />}
                onClick={handlePreview}
              >
                {t("Preview")}
              </Button>
            </ListItem>
            <TitleEdit
              value={state.data.title}
              onChange={(value) =>
                dispatch({ type: "data", field: "title", value })
              }
            />
            <FeatureImageEdit
              value={state.data.feature}
              onChange={(value) =>
                dispatch({ type: "data", field: "feature", value })
              }
              onRemove={() =>
                dispatch({ type: "data", field: "feature", value: null })
              }
            />
            <VisibilitySelect
              value={state.data.visibility}
              onChange={(value) =>
                dispatch({ type: "data", field: "visibility", value })
              }
            />
          </React.Fragment>
        }
      >
        <Box sx={{ py: 6 }}>
          <Container maxWidth="md">
            <ContentHeader
              label={state.data.title}
              breadcrumbs={[
                { label: t("Home"), to: "/" },
                { label: `${state.data.title}` },
              ]}
            />
            {state.loading ? (
              <List>
                <ListItem divider sx={{ backgroundColor: "background.paper" }}>
                  <ListItemText
                    primary={<Skeleton width="50%" height={24} />}
                    secondary={<Skeleton width="35%" height={16} />}
                  />
                </ListItem>
              </List>
            ) : (
              <SortContainerCard
                items={state.data.contents}
                onSortEnd={handleOnSortEnd}
                component={(doc, index) => (
                  <SortCardItem
                    itemIndex={index}
                    itemId={doc.key}
                    label={doc.header}
                    desc={doc.body}
                    image={doc.image}
                    index={index}
                    length={state.data.contents.length}
                    key={doc.key}
                  >
                    <IconButtonAbsolute
                      top={3}
                      right={6.5}
                      icon={["far", "arrows"]}
                      onClick={() =>
                        dispatch({ type: "set", data: { pos: doc } })
                      }
                    />
                    <IconButtonAbsolute
                      top={3}
                      right={2}
                      icon={["far", "ellipsis-vertical"]}
                      onClick={({ currentTarget }) =>
                        dispatch({
                          type: "set",
                          data: { menu: { elem: currentTarget, content: doc } },
                        })
                      }
                    />
                  </SortCardItem>
                )}
                useDragHandle
                axis="xy"
              />
            )}
            <FabGroup>
              <FabIcon
                icon="plus"
                color="info"
                onClick={() =>
                  dispatch({
                    type: "set",
                    data: {
                      edit: { isEdit: false, data: new SlideshowContent() },
                    },
                  })
                }
              />
              <FabIcon icon="save" color="success" onClick={handleSave} />
            </FabGroup>
            <PageSlideContent
              content={state.edit?.data}
              edit={state.edit?.isEdit}
              onConfirm={handlePushContent}
              onClose={() => dispatch({ type: "set", data: { edit: null } })}
            />
            {state.pos?.image?.image && (
              <DialogImagePosition
                open={Boolean(state.pos)}
                onSave={handleChangePos(state.pos)}
                onClose={() => dispatch({ type: "set", data: { pos: null } })}
                image={state.pos.image.image}
                value={state.pos.image.pos}
                ratio={2 / 3}
              />
            )}
          </Container>
        </Box>
        <Menu
          anchorEl={state.menu?.elem}
          open={Boolean(state.menu?.elem)}
          onClose={() => dispatch({ type: "set", data: { menu: null } })}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <MenuItem
            onClick={() =>
              state.menu &&
              dispatch({
                type: "set",
                data: {
                  menu: null,
                  edit: { isEdit: true, data: state.menu.content },
                },
              })
            }
          >
            {t("Edit")}
          </MenuItem>
          <MenuItem
            onClick={() =>
              state.menu?.content &&
              dispatch({
                type: "set",
                data: {
                  menu: null,
                  data: state.data.contenting.delete(state.menu.content),
                },
              })
            }
            sx={{ color: "error.main" }}
          >
            {t("Remove")}
          </MenuItem>
        </Menu>
      </MainContainer>
    </>
  );
};
