import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import SwiperCore, {
  Virtual,
  Keyboard,
  Mousewheel,
  Navigation,
  Pagination,
  Scrollbar,
  Parallax,
  Zoom,
  Lazy,
  Controller,
  A11y,
  History,
  HashNavigation,
  Autoplay,
  EffectFade,
  EffectCube,
  EffectFlip,
  EffectCoverflow,
  Thumbs,
} from "swiper";
import { Box, Button, ButtonProps, IconButton } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faPause,
  faPlay,
} from "@fortawesome/pro-duotone-svg-icons";
import styled from "@emotion/styled";
import { connectSlideshow, SlideShow } from "../../components/Slideshow";
import { useCore } from "../../components/core-sub/context";
import MainContainer from "../../components/core-sub/MainContainer";
import { Slideshow as SL } from "../../controllers/slide";

const Root = styled(Box)({
  position: "fixed",
  bottom: 0,
  left: 0,
  width: "100%",
  height: "calc(100% - 64px)",
  // overflow: "hidden",
});

const BackButton = styled((props: ButtonProps) => {
  const { t } = useCore();
  return (
    <Button
      variant="outlined"
      size="small"
      startIcon={<FontAwesomeIcon icon={faChevronLeft} />}
      {...props}
    >
      {t("Back")}
    </Button>
  );
})({});

SwiperCore.use([
  Virtual,
  Keyboard,
  Mousewheel,
  Navigation,
  Pagination,
  Scrollbar,
  Parallax,
  Zoom,
  Lazy,
  Controller,
  A11y,
  History,
  HashNavigation,
  Autoplay,
  EffectFade,
  EffectCube,
  EffectFlip,
  EffectCoverflow,
  Thumbs,
]);

export const PageSlidePreview = connectSlideshow(({ slider }) => {
  const { slideId } = useParams();
  const { user } = useCore();
  const location = useLocation();
  const [state, setState] = useState<{
    loading: boolean;
    data: SL | null;
    isPreview?: boolean;
    previewId?: string;
  }>({
    loading: true,
    data: null,
  });

  const nav = useNavigate();

  const handleBack = () => {
    const newState = { id: state.previewId, data: state.data?.data() };
    nav(state.previewId ? `/edit/${state.previewId}` : "/create", {
      state: newState,
    });
  };
  const handleAutoPlay = () => {
    if (slider) {
      if (slider.swiper.autoplay.running) {
        slider.swiper.autoplay.stop();
      } else {
        slider.swiper.autoplay.start();
      }
    }
  };

  useEffect(() => {
    if (user.loading === false && user.data) {
      const state = location.state as any;
      if (slideId) {
        SL.getOne(slideId).then((data) => {
          setState((s) => ({ ...s, data, loading: false }));
        });
      } else if (state) {
        setState((s) => ({
          ...s,
          data: new SL(state),
          loading: false,
          previewId: state.id,
          isPreview: true,
        }));
      }
    } else {
      setState((s) => ({ ...s, loading: true }));
    }
  }, [user, slideId, location.state]);
  
  return (
    <MainContainer
      loading={state.loading}
      startActions={state.isPreview && <BackButton onClick={handleBack} />}
      endActions={
        <IconButton onClick={handleAutoPlay}>
          <FontAwesomeIcon
            icon={slider?.swiper.autoplay.running ? faPause : faPlay}
          />
        </IconButton>
      }
    >
      <Root>
        <SlideShow
          title={state.data?.title}
          feature={state.data?.feature}
          date={state.data?.datemodified}
          slides={state.data?.toSlides() ?? []}
          user={state.data?.user}
          slideId={state.data?.id}
        />
      </Root>
    </MainContainer>
  );
});
