import {
  Slideshow,
  SlideshowContent,
  SlideshowSetData,
} from "../../controllers/slide";

export type EditStateAction<S extends keyof SlideshowSetData> =
  | {
      type: "data";
      field: S;
      value:
        | SlideshowSetData[S]
        | ((data: SlideshowSetData[S]) => SlideshowSetData[S]);
    }
  | { type: "dataset"; value: (data: Slideshow) => Slideshow }
  | { type: "set"; data: Partial<EditState> }
  | { type: "update"; value: EditState | ((data: EditState) => EditState) };

export class EditState {
  loading: boolean;
  restrict: boolean;
  data: Slideshow;
  edit: null | { isEdit: boolean; data: SlideshowContent };
  menu: null | { elem: Element; content: SlideshowContent };
  pos: null | SlideshowContent

  constructor(data?: Partial<EditState>) {
    this.loading = data?.loading ?? true;
    this.restrict = data?.restrict ?? false;
    this.data = data?.data ?? new Slideshow();
    this.edit = data?.edit ?? null;
    this.menu = data?.menu ?? null;
    this.pos = data?.pos ?? null;
  }

  set<T extends keyof this>(
    field: T,
    value: this[T] | ((data: this[T]) => this[T])
  ): EditState {
    this[field] = value instanceof Function ? value(this[field]) : value;
    return new EditState(this);
  }

  static reducer(
    state: EditState,
    action: EditStateAction<keyof SlideshowSetData>
  ): EditState {
    switch (action.type) {
      case "set":
        return new EditState({ ...state, ...action.data });
      case "update":
        return new EditState(
          action.value instanceof Function ? action.value(state) : action.value
        );
      case "data":
        return state.set("data", (data) =>
          data.set(action.field, action.value)
        );
      case "dataset":
        return state.set("data", action.value(state.data));
      default:
        return state;
    }
  }
}
