import { Box, Button, Stack, TextField } from "@mui/material";
import React, { ChangeEvent } from "react";
import { styled } from "@mui/system";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCore } from "../../components/core-sub/context";
import { DialogCompact } from "../../components/core-sub/DialogCompact";
import { KuiButton } from "../../components/core-sub/KuiButton";
import { StockDisplay } from "../../components/core-sub/StockDisplay";
import {
  StockImageTypes,
  StockPicker,
} from "../../components/core-sub/StockPicker";
import {
  SlideshowContent,
  SlideshowContentData,
} from "../../controllers/slide";

export type SlideContentProps = {
  content?: SlideshowContent | null;
  edit?: boolean
  onClose: () => void;
  onConfirm: (data: SlideshowContent) => void;
};

const Root = styled(Box)(({ theme }) => ({
  marginTop: 16,
  "& .rdw-editor-toolbar": {
    border: `solid 1px ${theme.palette.divider}`,
    borderBottom: "none",
    marginBottom: 0,
  },
  "& .rdw-editor-main": {
    marginTop: 0,
    border: `solid 1px ${theme.palette.divider}`,
  },
  "& .public-DraftStyleDefault-block": {
    height: 180,
  },
}));

export const PageSlideContent = (props: SlideContentProps) => {
  const { t } = useCore();
  const [data, setData] = React.useState<SlideshowContent>(
    new SlideshowContent()
  );
  const [state, setState] = React.useState<{ saving: boolean }>({
    saving: false,
  });

  const [open, onOpen] = React.useState<boolean>(false);
  const handleChange =
    (field: keyof SlideshowContentData) =>
    ({ target: { value } }: ChangeEvent<HTMLInputElement>) =>
      setData((d) => d.set(field, value));
  const handleImageChange = (images: StockImageTypes[]) => {
    if (images.length) {
      const { _id, blurhash, width, height } = images[0];
      setData((d) =>
        d.set("image", { image: { _id, blurhash, width, height } })
      );
    }
  };
  const handleConfirm = async () => {
    setState((s) => ({ ...s, saving: true }));
    await props.onConfirm(data);
    setState((s) => ({ ...s, saving: false }));
  };

  React.useEffect(() => {
    if (props.content) {
      setData(props.content);
    } else {
      setData(new SlideshowContent());
    }
  }, [props.content]);

  return (
    <>
      <DialogCompact
        open={Boolean(props.content) || state.saving}
        onClose={props.onClose}
        icon={props.edit ? "edit" : "plus-circle"}
        title={
          props.edit
            ? t("Edit $Name", { name: t("Slide") })
            : t("Add $Name", { name: t("Slide") })
        }
        actions={
          <>
            <Button
              variant="outlined"
              color="info"
              startIcon={<FontAwesomeIcon icon={["far", "image"]} />}
              onClick={() => onOpen(true)}
            >
              {t("Change Image")}
            </Button>
            <Box flex={1} />
            {state.saving ? (
              <KuiButton loading tx="confirm" />
            ) : (
              <>
                <KuiButton
                  tx="confirm"
                  disabled={!data.isComplete()}
                  onClick={handleConfirm}
                />
              </>
            )}
          </>
        }
      >
        <Root>
          <Stack spacing={2}>
            <TextField
              label={t("Title")}
              variant="outlined"
              fullWidth
              value={data?.header || ""}
              onChange={handleChange("header")}
            />
            <TextField
              label={t("Description")}
              variant="outlined"
              fullWidth
              multiline
              rows={6}
              value={data?.body || ""}
              onChange={handleChange("body")}
            />
            {data.image && <StockDisplay ratio={2 / 3} {...data.image} />}
          </Stack>
        </Root>
      </DialogCompact>
      <StockPicker
        open={open}
        onConfirm={handleImageChange}
        onClose={() => onOpen(false)}
      />
    </>
  );
};
