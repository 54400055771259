import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, {
  ComponentType,
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useState,
} from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { StockDisplayProps } from "../core-sub/StockDisplay";
import { NextButton, PrevButton } from "./button";
import { PaginationBlock } from "./pagination";
import { Slide } from "./slide";
import { SlideCover } from "./slide.cover";
import { SlideItem } from "./slide.item";
import { SlideRoot } from "./slide.root";
import "swiper/css";

//SECTION - TYPES
//ANCHOR - SlideValue
export type SlideValue = {
  key: string;
  title?: string;
  image?: StockDisplayProps | null;
  secondary?: string;
};

//ANCHOR - SlideShowProps
export type SlideShowProps = {
  title?: string;
  feature?: StockDisplayProps | null;
  date?: number;
  slides: SlideValue[];
  slideId?: string
  user?: string
};
//!SECTION

export type SlideshowContextState = {
  slider: Slide | null;
  setSlider: Dispatch<SetStateAction<Slide | null>>;
};
const SlideshowContext = createContext<SlideshowContextState>({
  slider: null,
  setSlider: () => {},
});

export const connectSlideshow =
  <T extends {}>(Comp: ComponentType<T & SlideshowContextState>) =>
  (props: T) => {
    const [slider, setSlider] = useState<Slide | null>(null);

    return (
      <SlideshowContext.Provider value={{ slider, setSlider }}>
        <Comp {...props} slider={slider} setSlider={setSlider} />
      </SlideshowContext.Provider>
    );
  };

export const SlideShow = React.memo(
  (props: SlideShowProps) => {
    const { slider, setSlider } = useContext(SlideshowContext);

    return (
      <SlideRoot>
        <Swiper
          {...Slide.options}
          onSlideChange={(swiper) => setSlider(new Slide(swiper))}
          // onSwiper={(swiper) => console.log(swiper)}
          onInit={(swiper) => {
            swiper.autoplay.stop();
            setSlider(new Slide(swiper));
          }}
        >
          {props.title && (
            <SwiperSlide>
              <SlideCover
                primary={props.title}
                image={props.feature}
                date={props.date}
                slideId={props.slideId}
                user={props.user}
              />
            </SwiperSlide>
          )}
          {props.slides.map((s, index, slides) => (
            <SwiperSlide key={s.key}>
              <SlideItem
                title={s.title}
                image={s.image}
                counter={`${index + 1}/${slides.length}`}
                secondary={s.secondary}
              />
            </SwiperSlide>
          ))}
          <PaginationBlock
            className="swiper-pagination"
            cover={slider?.swiper.activeIndex === 0}
          />
          <NextButton
            className="swiper-button-next"
            cover={slider?.swiper.activeIndex === 0}
            disabled={slider?.swiper.isEnd}
          >
            <FontAwesomeIcon size="4x" icon={faChevronRight} />
          </NextButton>
          <PrevButton
            className="swiper-button-prev"
            disabled={slider?.swiper.isBeginning}
          >
            <FontAwesomeIcon size="4x" icon={faChevronLeft} />
          </PrevButton>
        </Swiper>
      </SlideRoot>
    );
  }
);
